import {
	EntityNumber,
	LocaleMessageType,
	LocaleMessageTypeArray,
} from '@/src/modules/common/models/common.models';
import { ProjectEntity } from '@/src/modules/events/models/projects.models';
import { SeasonEntity } from '@/src/modules/events/models/seasons.models';
import {
	Content,
	Invariant,
	InvariantArray,
	ObjectStatus,
} from '@/src/modules/srm/models/squidex.models';

export type EventDate = {
	endDay: number;
	endMonth: LocaleMessageType;
	endTimestamp: string;
	startDay: number;
	startMonth: LocaleMessageType;
	startTimestamp: string;
};

export type EventDatesAlt = {
	hint: LocaleMessageType;
	days: Record<string, EventDatesType>;
};

type EventCfpStatus = 'FUTURE' | 'PUBLIC' | 'PRIVATE' | 'RESTRICTED' | 'CLOSED';
export type EventDatesType = 'OFFLINE' | 'ONLINE' | 'HYBRID';
type EventPlatform = 'JUGRU' | 'YOUTUBE' | 'VK_VIDEO';
export type EventType = 'CONFERENCE' | 'MEETUP';

export type EventEntity = {
	readonly cfpStatus?: EventCfpStatus | string;
	readonly datesAlt?: EventDatesAlt;
	readonly hint?: string;
	readonly platform?: EventPlatform;
	readonly seasonId?: number;
	readonly type?: EventType;
	readonly venueId?: number;
	readonly version?: string;
	readonly projectId?: string;
	readonly content?: EventContent;
	readonly workspaceId?: string;
	readonly modules?: EventModule[];

	// deprecated
	// readonly jira?: JiraProjectVersion;
	readonly dates?: EventDate[];
	readonly stage?: EventStage;
	// readonly phase?: EventPhase;

	// computed
	isFinished?: boolean;
	rights?: EventRights;
	season?: SeasonEntity;
	project?: ProjectEntity;
} & EntityNumber &
	EventStatusEntity;

export type EventRights = {
	[k in EventRightsTypes]: boolean;
};

export type EventModule = {
	readonly name: EventModuleName;
	// computed
	rights?: ModuleRights;
} & EntityNumber;

export type EventModuleName = 'event/publication' | 'event/schedule';

export type ModuleRights = {
	[k in EventModuleRightsTypes]: boolean;
};

export enum EventModuleRightsTypes {
	canViewPublicationsModule = 'canViewPublicationsModule',
	canListProposalsForPublications = 'canListProposalsForPublications',
	canViewScheduleModule = 'canViewScheduleModule',
	canListScheduleVersions= 'canListScheduleVersions',
	canListProposalsForSchedule = 'canListProposalsForSchedule',

	canViewActivitiesModule = 'canViewActivitiesModule',
	canViewLiveScheduleModule = 'canViewLiveScheduleModule',
	canViewSupportCalendarModule = 'canViewSupportCalendarModule',
	canViewTeamModule = 'canViewTeamModule',
	canViewReportModule = 'canViewReportModule',
	canViewRatingsModerationModule = 'canViewRatingsModerationModule',
}

enum EventRightsTypes {
	canPatch = 'canPatch',
	canArchive = 'canArchive',
	canFindMember = 'canFindMember',
	canAddMember = 'canAddMember',
}

interface EventContentData {
	readonly title?: LocaleMessageType;
	readonly description?: LocaleMessageType;
	readonly brandColors?: InvariantArray<string>;
	readonly coloredLogo?: LocaleMessageTypeArray;
	readonly logoUri?: Invariant<string>;
	readonly monochromeLogo?: LocaleMessageTypeArray;
	readonly playlistUri?: Invariant<string>;
	readonly siteUri?: LocaleMessageType;
	readonly srmId?: Invariant<string>;
	readonly srmProjectId?: Invariant<string>;
	readonly srmVersion?: Invariant<string>;

	// deprecated
	// readonly name?: LocaleMessageType;
	// readonly datesApproved?: boolean;
	// readonly datesDetermined?: boolean;
	readonly period?: EventPeriod;
	// readonly season?: SeasonEntity;
}

export type EventContent = Content<EventContentData>;

export type EventObjectStatus = ObjectStatus & 'reactivated';

export type EventStatusEntity = {
	readonly objectStatus?: EventObjectStatus;
};

export type EventPeriod = {
	readonly startsAt: string;
	readonly endsAt: string;
};

export type EventStage = 'UNKNOWN' | 'PLANNING' | 'PREPARING' | 'PROGRESSING' | 'COMPLETED';

// export type EventPhase = {
// 	readonly name?: 'Future event';
// 	readonly startsAt?: Date;
// 	readonly finishedAt?: Date;
// 	readonly stage?: EventStage;
// 	readonly type?: 'begin_phase' | 'phase' | 'end_phase';
// 	readonly alertingEnabled?: boolean;
// 	readonly alertingInterval?: null;
// };
