import(/* webpackMode: "eager" */ "/build/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/build/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/build/node_modules/primeflex/primeflex.css");
import(/* webpackMode: "eager" */ "/build/node_modules/primeicons/primeicons.css");
import(/* webpackMode: "eager" */ "/build/src/app/globals.scss");
import(/* webpackMode: "eager" */ "/build/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/build/src/modules/auth/components/Login.tsx");
import(/* webpackMode: "eager" */ "/build/src/modules/auth/components/Logout.tsx");
import(/* webpackMode: "eager" */ "/build/src/modules/auth/components/MobileUserInfo.tsx");
import(/* webpackMode: "eager" */ "/build/src/modules/auth/components/TryVerifyAuth.tsx");
import(/* webpackMode: "eager" */ "/build/src/modules/auth/components/UserActions.tsx");
import(/* webpackMode: "eager" */ "/build/src/modules/common/components/LocaleSwitcher/LocaleSwitcher.tsx");
import(/* webpackMode: "eager" */ "/build/src/modules/common/components/navigation/AppSidebar.tsx");
import(/* webpackMode: "eager" */ "/build/src/modules/common/components/navigation/BreadCrumb/BreadCrumb.tsx");
import(/* webpackMode: "eager" */ "/build/src/modules/common/components/ThemeSwitcher/ThemeSwitcher.tsx");
import(/* webpackMode: "eager" */ "/build/src/modules/common/hooks/useTheme.tsx")