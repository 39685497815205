'use client';

import React, { FC, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import styles from './sidebar.module.scss';

import { AuthRolesService } from '@/src/modules/auth/services/authRoles.service';
import {
	createDashboardItem,
	createEventsItem,
	createUsersItem,
} from '@/src/modules/common/components/navigation/menuItems';
import { useMounted } from '@/src/modules/common/hooks/useMounted';
import {
	routes,
	routesParts,
	urlWithId,
	urlWithIds,
} from '@/src/modules/common/routes/common.routes';
import { Routes } from '@/src/modules/common/routes/common.routes.types';
import {
	CommonStyledComponent,
	WithPrivateContentComponent,
} from '@/src/modules/common/types/common.types';
import { Link } from '@/src/modules/common/utils';
import { useEvent } from '@/src/modules/events/hooks/useEvent';
import { useProjects } from '@/src/modules/events/hooks/useProjects';
import { Locale, useAnyTranslations } from '@/src/modules/i18n';
import { CreateWorkspaceButton } from '@/src/modules/srm/components/CreateWorkspaceButton';
import { createEventMenuItems } from '@/src/modules/srm/utils/events-menu.utils';
import { useWorkspaceFromContext } from '@/src/modules/workspaces/hooks/useWorkspaceFromContext';
import { workspacesActions } from '@/src/modules/workspaces/models/workspaces.actions';
import { useLocale, useTranslations } from 'next-intl';
import Image from 'next/image';
import { useRouter, useSelectedLayoutSegments } from 'next/navigation';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { MenuItem } from 'primereact/menuitem';
import { PanelMenu } from 'primereact/panelmenu';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';

import Logo from '../../../../../public/static/img/many-to-many-logo.png';

type EventPageInfo = {
	eventPage: string;
	menuItems: MenuItem[];
};

export type AppSidebarProps = CommonStyledComponent & WithPrivateContentComponent;

export const AppSidebar: FC<AppSidebarProps> = ({ showPrivateContent, className }) => {
	const urlParts = useSelectedLayoutSegments();
	const locale = useLocale();

	const {
		workspaces,
		workspace,
		switchDefaultWorkspace,
		isLoading: workspacesIsLoading,
	} = useWorkspaceFromContext(showPrivateContent);

	const { projects } = useProjects(workspace?.slug ?? workspace?.id, showPrivateContent);
	const [event] = useEvent(+urlParts?.[2]);

	const t = useTranslations('sidebar');
	const tPages = useAnyTranslations('pages');

	const [isGlobalAdmin, setIsGlobalAdmin] = useState<boolean>(false);

	const router = useRouter();

	const [eventPageInfo, setEventPageInfo] = useState<EventPageInfo>();

	const items = useMemo(() => {
		const result: MenuItem[] = [];

		if (!(workspace?.slug ?? workspace?.id)) {
			return [];
		}

		result.push(createDashboardItem(t, workspace?.slug ?? workspace?.id));
		result.push(createEventsItem(t, workspace?.slug ?? workspace?.id));

		if (isGlobalAdmin) {
			result.push(createUsersItem(t, false, workspace?.slug ?? workspace?.id));
		}

		return result;
	}, [isGlobalAdmin, workspace?.slug, workspace?.id]);

	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

	const mounted = useMounted();

	useEffect(() => {
		const isEventsPage = urlParts?.[1] === Routes.WorkspaceEvents && event?.id !== undefined;

		const workspaceId = urlParts?.[0];
		const eventPage = urlParts?.[3];

		if (!event || (isEventsPage && !eventPage)) {
			setEventPageInfo(undefined);
			return;
		}

		const menuItems = createEventMenuItems({
			tPages,
			locale: locale as Locale,
			event,
			workspaceId,
			linkClassName: classNames(styles.link, 'px-3'),
			spanClassName: 'text-base',
			checkedLinkClassName: styles.checked,
			checkedId: `event/${eventPage}`,
		});

		setEventPageInfo({
			eventPage,
			menuItems,
		});
	}, [event, urlParts?.[0], urlParts?.[1], urlParts?.[3]]);

	useEffect(() => {
		const rolesService = new AuthRolesService();
		setIsGlobalAdmin(rolesService.isGlobalAdmin);
	}, []);

	const setWorkspace = (workspaceId: string) => {
		const w = workspaces?.find((workspace) => workspace.id === workspaceId);
		if (w !== undefined) {
			switchDefaultWorkspace(workspaceId);

			if (urlParts[1] === Routes.WorkspaceEvents) {
				router.push(
					urlWithId(
						routes[Routes.WorkspaceEvents].path,
						routesParts.workspace,
						w.slug ?? workspaceId,
					),
				);
			} else if (urlParts[1] === Routes.WorkspaceUsers) {
				router.push(
					urlWithId(
						routes[Routes.WorkspaceUsers].path,
						routesParts.workspace,
						w.slug ?? workspaceId,
					),
				);
			} else {
				router.push(
					urlWithId(
						routes[Routes.Dashboard].path,
						routesParts.workspace,
						w.slug ?? workspaceId,
					),
				);
			}
		}
	};

	const renderCreateEventButton = () => {
		if (!projects?.some((p) => p.rights?.canCreateEvent)) {
			return null;
		}

		return (
			<Button
				label={t('add-event')}
				severity="info"
				icon="pi pi-plus"
				className="w-14rem h-3rem"
				onClick={() => {
					router.push(
						`${urlWithIds(routes[Routes.CreateEvent].path, [
							{
								match: routesParts.workspace,
								value: workspace?.slug ?? workspace?.id ?? '',
							},
						])}/1`,
					);
				}}
			/>
		);
	};

	const renderCreateButtons = () => {
		if (!showPrivateContent) {
			return null;
		}

		if (urlParts[1] === Routes.WorkspaceEvents && urlParts[2] !== undefined) {
			return null;
		}

		return (
			<div
				className={classNames('gap-2 flex flex-column align-items-center mt-4', {
					'mt-6': workspaces?.length && workspaces?.length > 0,
				})}
			>
				<CreateWorkspaceButton />
				{renderCreateEventButton()}
			</div>
		);
	};

	const render = (withImage = true) => (
		<>
			{withImage && (
				<div className="py-3 bg-surface-600 z-1 pl-3">
					<Link href="">
						<Image priority width={136} height={48} src={Logo} alt="Image" />
					</Link>
				</div>
			)}

			<div className="flex h-full px-10">
				<div className="overflow-y-auto py-3 px-1 flex flex-column">
					{!mounted && <Skeleton className="mb-4" height="30px" />}
					{mounted && (
						<Dropdown
							value={workspace?.id}
							onChange={(e) => setWorkspace(e.value)}
							options={workspacesActions.workspacesSelectOptions(workspaces)}
							optionLabel="label"
							optionValue="value"
							checkmark
							className="mb-4"
							panelClassName="text-sm"
							loading={workspacesIsLoading}
							placeholder={workspace === undefined ? 'Loading...' : undefined}
							disabled={!workspaces?.length ? true : workspaces.length < 2}
						/>
					)}
					<PanelMenu
						model={items}
						className={classNames('w-full md:w-15rem', styles.sidebar)}
						multiple
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						transitionOptions={{ disabled: true }}
					/>
					{renderCreateButtons()}
					{showPrivateContent && eventPageInfo && (
						<>
							<Divider className="opacity-10 mb-auto" style={{ marginTop: '2vh' }} />

							<PanelMenu
								model={eventPageInfo.menuItems}
								className={classNames('w-full md:w-15rem mt-5', styles.sidebar)}
								multiple
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								transitionOptions={{ disabled: true }}
							/>

							{/*<CompletedWidget percent={10} />*/}

							{/*<CommentNote*/}
							{/*	tips={['Информация на английском языке', 'Расписание', 'Дизайн']}*/}
							{/*/>*/}
						</>
					)}
				</div>
			</div>
		</>
	);

	if (!showPrivateContent) {
		return null;
	}

	return (
		<>
			<section
				className={classNames(
					'h-full lg:h-auto hidden xl:block flex-shrink-0 xl:sticky w-full md:w-auto max-w-min',
					className,
					styles.appDesktopSideBar,
				)}
			>
				{render()}
			</section>

			<div
				className={classNames(
					'block xl:hidden absolute z-1 top-0 left-0',
					styles.appMobileSideBar,
				)}
			>
				<div className="mt-2 pt-2 pl-2 md:mt-0 md:pl-2 md:pt-3 xl:ml-4">
					<Button
						icon="pi pi-bars text-xl text-color"
						rounded
						text
						aria-label="side-bar"
						onClick={() => setSidebarOpen(!sidebarOpen)}
						className={classNames('mt-1', styles.mobileSideBarToggleButton)}
					/>

					<Sidebar
						visible={sidebarOpen}
						onHide={() => setSidebarOpen(false)}
						className={classNames('bg-surface-600 w-auto', styles.sidebar_mobile)}
					>
						<div className="p-2 bg-surface-600">
							<Link href="">
								<Image priority width={136} height={48} src={Logo} alt="Image" />
							</Link>
						</div>
						{render(false)}
					</Sidebar>
				</div>
			</div>
		</>
	);
};
